import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Router } from "react-router-dom";
import Nav from "./components/Nav";
import Loading from "./components/Loading";
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/main.scss';

const sampleUser = {
	"id": 1,
	"username": "FuckShit",
	"email": "admin@chafedyo.com",
	"avatar": "/uploads/fuckshit/avatar.png",
	"cover_art": "/uploads/fuckshit/cover.png",
	"created_at": "1970-01-19T05:10:51.398Z",
	"active": 1,
	"info": {
		"userType": "Normal-Ass User",
		"artistType": "Noneartist",
		"location": "None",
		"custom": "Huge Hand"
	},
	"status": "Not doing what I'm supposed to",
	art: {
		featured: {
			title: 'Gary',
			url: './art/art-sample-1.png',
			created: 'November 8th, 2019',
		},
		recent: [{
			title: '',
			url: './art/art-sample-3.png',
			created: 'November 8th, 2019',
		}, {
			title: '',
			url: './art/art-sample-2.png',
			created: 'November 8th, 2019',
		}, {
			title: '',
			url: './art/art-sample-4.png',
			created: 'November 8th, 2019',
		}, {
			title: '',
			url: './art/art-sample-6.png',
			created: 'November 8th, 2019',
		}, {
			title: '',
			url: './art/art-sample-5.png',
			created: 'November 8th, 2019',
		}, ]
	},
	"comments": [{
		"id": 10,
		"entity_id": 1,
		"entity_type": 1,
		"user_id": 1,
		"user": {
			"id": 1,
			"username": "FuckShit",
			"email": "admin@chafedyo.com",
			"avatar": "/uploads/fuckshit/avatar.png",
			"cover_art": "/uploads/fuckshit/cover.png",
			"created_at": 1573851398,
			"active": 1,
			"info": {
				"userType": "",
				"artistType": "",
				"location": "",
				"custom": ""
			}
		},
		"is_reply": 0,
		"reply_to": null,
		"replies": [{
			"id": 13,
			"entity_id": 1,
			"entity_type": 1,
			"user_id": 1,
			"user": {
				"id": 1,
				"username": "FuckShit",
				"email": "admin@chafedyo.com",
				"avatar": "/uploads/fuckshit/avatar.png",
				"cover_art": "/uploads/fuckshit/cover.png",
				"created_at": 1573851398,
				"active": 1,
				"info": {
					"userType": "",
					"artistType": "",
					"location": "",
					"custom": ""
				}
			},
			"is_reply": 1,
			"reply_to": 10,
			"replies": [{
				"id": 14,
				"entity_id": 1,
				"entity_type": 1,
				"user_id": 2,
				"user": {
					"id": 2,
					"username": "Szar",
					"email": "s@chafedyo.com",
					"avatar": "/uploads/avatar-default.png",
					"cover_art": "",
					"created_at": 1573851418,
					"active": 1,
					"info": {
						"userType": "",
						"artistType": "",
						"location": "",
						"custom": ""
					}
				},
				"is_reply": 1,
				"reply_to": 13,
				"replies": [],
				"text": "HELP",
				"created_at": 1574108530,
				"active": 1
			}],
			"text": "your the welcome",
			"created_at": 1574108499,
			"active": 1
		}],
		"text": "thanks",
		"created_at": 1574107424,
		"active": 1
	}, {
		"id": 9,
		"entity_id": 1,
		"entity_type": 1,
		"user_id": 1,
		"user": {
			"id": 1,
			"username": "FuckShit",
			"email": "admin@chafedyo.com",
			"avatar": "/uploads/fuckshit/avatar.png",
			"cover_art": "/uploads/fuckshit/cover.png",
			"created_at": 1573851398,
			"active": 1,
			"info": {
				"userType": "",
				"artistType": "",
				"location": "",
				"custom": ""
			}
		},
		"is_reply": 0,
		"reply_to": null,
		"replies": [],
		"text": "wait",
		"created_at": 1574107416,
		"active": 1
	}, {
		"id": 8,
		"entity_id": 1,
		"entity_type": 1,
		"user_id": 1,
		"user": {
			"id": 1,
			"username": "FuckShit",
			"email": "admin@chafedyo.com",
			"avatar": "/uploads/fuckshit/avatar.png",
			"cover_art": "/uploads/fuckshit/cover.png",
			"created_at": 1573851398,
			"active": 1,
			"info": {
				"userType": "",
				"artistType": "",
				"location": "",
				"custom": ""
			}
		},
		"is_reply": 0,
		"reply_to": null,
		"replies": [],
		"text": "thanks",
		"created_at": 1574107291,
		"active": 1
	}, {
		"id": 7,
		"entity_id": 1,
		"entity_type": 1,
		"user_id": 4,
		"user": {
			"id": 4,
			"username": "Nikaleles",
			"email": "nikaleles@gmail.com",
			"avatar": "/uploads/nikaleles/avatar.png",
			"cover_art": "/uploads/nikaleles/cover.png",
			"created_at": 1574025325,
			"active": 1,
			"info": {
				"userType": "",
				"artistType": "",
				"location": "",
				"custom": ""
			}
		},
		"is_reply": 0,
		"reply_to": null,
		"replies": [{
			"id": 12,
			"entity_id": 1,
			"entity_type": 1,
			"user_id": 1,
			"user": {
				"id": 1,
				"username": "FuckShit",
				"email": "admin@chafedyo.com",
				"avatar": "/uploads/fuckshit/avatar.png",
				"cover_art": "/uploads/fuckshit/cover.png",
				"created_at": 1573851398,
				"active": 1,
				"info": {
					"userType": "",
					"artistType": "",
					"location": "",
					"custom": ""
				}
			},
			"is_reply": 1,
			"reply_to": 7,
			"replies": [],
			"text": "hi im hugehand",
			"created_at": 1574108375,
			"active": 1
		}],
		"text": "this is just ass-back whatty",
		"created_at": 1574105883,
		"active": 1
	}, {
		"id": 1,
		"entity_id": 1,
		"entity_type": 1,
		"user_id": 1,
		"user": {
			"id": 1,
			"username": "FuckShit",
			"email": "admin@chafedyo.com",
			"avatar": "/uploads/fuckshit/avatar.png",
			"cover_art": "/uploads/fuckshit/cover.png",
			"created_at": 1573851398,
			"active": 1,
			"info": {
				"userType": "",
				"artistType": "",
				"location": "",
				"custom": ""
			}
		},
		"is_reply": 0,
		"reply_to": null,
		"replies": [{
			"id": 2,
			"entity_id": 1,
			"entity_type": 1,
			"user_id": 1,
			"user": {
				"id": 1,
				"username": "FuckShit",
				"email": "admin@chafedyo.com",
				"avatar": "/uploads/fuckshit/avatar.png",
				"cover_art": "/uploads/fuckshit/cover.png",
				"created_at": 1573851398,
				"active": 1,
				"info": {
					"userType": "",
					"artistType": "",
					"location": "",
					"custom": ""
				}
			},
			"is_reply": 1,
			"reply_to": 1,
			"replies": [{
				"id": 11,
				"entity_id": 1,
				"entity_type": 1,
				"user_id": 1,
				"user": {
					"id": 1,
					"username": "FuckShit",
					"email": "admin@chafedyo.com",
					"avatar": "/uploads/fuckshit/avatar.png",
					"cover_art": "/uploads/fuckshit/cover.png",
					"created_at": 1573851398,
					"active": 1,
					"info": {
						"userType": "",
						"artistType": "",
						"location": "",
						"custom": ""
					}
				},
				"is_reply": 1,
				"reply_to": 2,
				"replies": [],
				"text": "hi hugehand",
				"created_at": 1574108361,
				"active": 1
			}],
			"text": "Huge Hand",
			"created_at": 1574102445,
			"active": 1
		}],
		"text": "Its me",
		"created_at": 1574102150,
		"active": 1
	}],
	"about": {
		"url": "",
		"content": "\n\t\t\t\t\t<p><center style=\"font-weight: bold; font-size: 18px;\">✿ hello it's me, huge hand ✿</center></p>\n\t\t\t\t\t<p><center style=\"padding: 6px 0;\">。˚✧˚。˚✧˚。˚✧˚。˚✧˚。˚✧˚ 。</center></p>\n\t\t\t\t\t<p>Nunc volutpat nisl quis purus scelerisque condimentum. Quisque enim arcu, fermentum non turpis a, interdum rutrum lacus. Maecenas pretium fermentum mattis.</p>\n\t\t\t\t\t<p>Ut quis dolor sit amet libero tristique commodo. Praesent euismod lacus vitae libero dignissim posuere. Donec massa nunc, suscipit ac erat nec, volutpat vehicula diam.</p>",
		"css": ""
	},
	"stats": {
		"pageviews": 294,
		"followers": 0,
		"uploads": 0,
		"comments": 0
	}
}
const Home = lazy(() => import('./pages/Home')),
	  Profile = lazy(() => import('./pages/Profile')),
	  Art = lazy(() => import('./pages/Art')),
	  SampleProfile = lazy(() => import('./pages/SampleProfile'));

class App extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			theme: localStorage.getItem('theme')===null||typeof localStorage.getItem('theme') === 'undefined'?'dark':localStorage.getItem('theme'),
			user: localStorage.getItem('user')===null||typeof localStorage.getItem('user') === 'undefined'?null:JSON.parse(localStorage.getItem('user'))
		}
		document.querySelector('body').classList.add(this.state.theme);
	}

	render() {
		
		return (
			<BrowserRouter>
				<div id="cyo" className={this.state.theme}>
					<Nav themeChange={ 
						(theme) => { 
							this.setState({theme:theme}); 
							var rm = theme==='dark'?'light':'dark',
								add = theme==='dark'?'dark':'light';
							document.querySelector('body').classList.remove(rm);
							document.querySelector('body').classList.add(add);
							localStorage.setItem('theme', add);
						}
					} 
					user={this.state.user}
					theme={this.state.theme} 
					onLogout={()=>{localStorage.setItem('user', null); this.setState({user: null}); setTimeout(()=>{window.location.reload(false);},750);  } }
					onLogin={(d)=>{ localStorage.setItem('user', JSON.stringify(d)); this.setState({user: d}); setTimeout(()=>{window.location.reload(false);},750); }}/>
					<div id="main">
						<Suspense fallback={<Loading />}>
							<Route exact path="/" render ={()=> <Home/>} />
							<Route path="/art/:id" render={(props) => <Art {...props} user={this.state.user} />}/>
							<Route path="/user/:username" render={(props) => <Profile {...props} user={this.state.user} />}/>
							<Route path="/sample/:username" render={(props) => <Profile {...props} user={sampleUser} debug={true} />}/>
						</Suspense>
					</div>
					<footer>
						<div className="footer--content">
							<div className="container">
								<div className="d-flex flex-row">
									<div className="flex-col">
										<div><a href="/" className="brand">cyo</a></div>
										
									</div>
									<div className="flex-col">
										<div className="footer--header">Sitemap</div>
										<ul>
											<li><a href="#">Browse</a></li>
											<li><a href="#">Discover</a></li>
											<li><a href="#">Learn</a></li>
											<li><a href="#">Forums</a></li>
										</ul>
									</div>
									<div className="flex-col">
										<div className="footer--header">About Us</div>
										<ul>
											<li><a href="#">About</a></li>
											<li><a href="#">Staff</a></li>
											<li><a href="#">Contact</a></li>
										</ul>
									</div>
									<div className="flex-col">
										<div className="footer--header">Help</div>
										<ul>
											<li><a href="#">Help Desk</a></li>
											<li><a href="#">F.A.Q.</a></li>
											<li><a href="#">Terms of Service</a></li>
											<li><a href="#">Privacy Policy</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="footer--copyright">
							<div className="container">
								<div className="copyright">© 2010-{new Date().getFullYear()}, cyo</div>
							</div>
						</div>
						
							
						
					</footer>
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Navbar,Nav as BsNav,NavDropdown,Form,Button,FormControl,Col,Modal} from "react-bootstrap";
import Auth from '../Auth';
const requireImage = require.context('../../images', true);
const config = require('../../config');
class Nav extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	
	render() {
		
		return (
			<Navbar bg="dark" expand="md">
					<Navbar.Brand href="/">cyo</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<BsNav className="mr-auto">
						<BsNav.Link href="#">Browse</BsNav.Link>
						<BsNav.Link href="#">Discover</BsNav.Link>
						<BsNav.Link href="#">Learn</BsNav.Link>
						<BsNav.Link href="#">Forums</BsNav.Link>
						</BsNav>
						<div className="theme--swap">{this.props.theme==='dark'?
							<a onClick={ ()=>{ this.props.themeChange('light'); } }><i className="fas fa-lightbulb"></i></a>
							:<a onClick={ ()=>{ this.props.themeChange('dark'); } }><i className="far fa-lightbulb"></i></a>
						}</div>
						
						<Auth user={this.props.user} onLogin={(d)=>{ this.props.onLogin(d); }} onLogout={(d)=>{ this.props.onLogout(); }} />
					</Navbar.Collapse>
				</Navbar>
		);
	}
}

export default Nav;
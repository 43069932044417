import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {Navbar,Nav as BsNav,NavDropdown,Form,Button,FormControl,Col,Modal} from "react-bootstrap";
import './Auth.scss';
const requireImage = require.context('../../images', true);
const config = require('../../config');
class Nav extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			"show_login": false,
			"show_signup": false,
			"show_success":false
		}
	}
	handleShow(m) {
		var s = m==="show_login"?{"show_login":true}:m==="show_signup"?{"show_signup":true}:{"show_success":true}
		this.setState(s)
	}
	handleClose(m) {
		var s = m==="show_login"?{"show_login":false}:m==="show_signup"?{"show_signup":false}:{"show_success":false}
		this.setState(s)
	}
	logout() {
		this.props.onLogout();
	}
	login(e) {
		e.preventDefault();
		axios.post(config.api_url+'/auth', 
			{
				"username":document.getElementById("username").value,
				"password":document.getElementById("password").value
			}, 
		).then((resp)=>{
			console.log(resp.data);
			if(resp.data.auth) {
				this.props.onLogin(resp.data);
			}
		})
		.catch(function (error) {
			console.log(error);
			console.log(error.response);
		});
	}
	signup(e) {
		e.preventDefault();
		axios.post(config.api_url+'/auth/register', 
			{
				"username":document.getElementById("username").value,
				"password":document.getElementById("password").value,
				"email":document.getElementById("email").value
			}, 
		).then((resp)=>{
			console.log(resp.data);
			if(resp.data.auth) {
				this.handleClose('show_signup');
				this.handleShow('show_success');
			}
		})
		.catch(function (error) {
			console.log(error);
			console.log(error.response);
		});
	}
	renderUserNav() {
		return (<div className="nav--user-menu">
		<NavDropdown className="avatar-dropdown" title={<div className="user--avatar"><img src={this.props.user.avatar} /></div>} id="theme_dropdown">
			<a className="dropdown-item" href="/submit">Submit Art</a>
			<a className="dropdown-item" href={"/user/"+this.props.user.username}>Profile</a>
			<a className="dropdown-item" onClick={()=>{this.logout()}}>Logout</a>
		</NavDropdown>
		</div> )
	}
	renderAuthNav(){
		return(
			<div className="nav-login">
						<Button variant="primary" onClick={()=>{this.handleShow('show_login')}}>
							Sign In
						</Button>
						<Button variant="primary" onClick={()=>{this.handleShow('show_signup')}}>
							Join
						</Button>
						<Modal id="login-modal" show={this.state.show_login} onHide={()=>{this.handleClose('show_login')}}>
							<Modal.Header>
								<Modal.Title>Sign In</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<form onSubmit={(e)=>{this.login(e)}}>
									<Form.Group controlId="formGridUsername">
										<Form.Label>Username</Form.Label>
										<Form.Control id="username" type="text" />
									</Form.Group>
									<Form.Group controlId="formGridPassword">
										<Form.Label>Password</Form.Label>
										<Form.Control id="password" type="password" />
									</Form.Group>
									<input type="submit" value="Sign In" className="btn btn-primary" />
							</form>
							<div className="login-register">New to cyo? <span className="join-btn" onClick={()=>{this.handleClose('show_login'); this.handleShow('show_signup')}}>Join now!</span></div>
							</Modal.Body>
						</Modal>
						<Modal id="register-modal" show={this.state.show_signup} onHide={()=>{this.handleClose('show_signup')}}>
							<Modal.Header>
								<Modal.Title>Join Cyo</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<form onSubmit={(e)=>{this.signup(e)}}>
									<Form.Group controlId="formGridUsername">
										<Form.Label>Username</Form.Label>
										<Form.Control id="username" type="text" placeholder="myspecialname" />
									</Form.Group>
									<Form.Group controlId="formGridPassword">
										<Form.Label>Email</Form.Label>
										<Form.Control id="email" type="email" placeholder="something@something.com" />
									</Form.Group>
									<Form.Group controlId="formGridPassword">
										<Form.Label>Password</Form.Label>
										<Form.Control id="password" type="password" />
									</Form.Group>
									<input type="submit" value="Signup" className="btn btn-primary" />
							</form>
							<div className="login-register">Already have an account? <span className="join-btn" onClick={()=>{this.handleClose('show_signup'); this.handleShow('show_login')}}>Sign in.</span></div>

							
							</Modal.Body>
							
						</Modal>
		
						<Modal show={this.state.show_success} onHide={()=>{this.handleClose('show_success')}}>
							<Modal.Header>
								<Modal.Title>Success!</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								You may now login.
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={()=>{this.handleClose('show_success')}}>Close</Button>
							</Modal.Footer>
							
						</Modal>
					</div>
		)
	}
	render() {
		
		return (
			
							
					<div id="auth">
						{this.props.user!==null?this.renderUserNav():this.renderAuthNav()}
					</div>

			
		);
	}
}

export default Nav;